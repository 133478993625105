/* CSS - Cascading Style Sheet */
/* Palette color codes */
/* Palette URL: http://paletton.com/#uid=62U0z0kvif7tEkEuei6xUbwy+8z */

/* Feel free to copy&paste color codes to your application */

/* As hex codes */
:root {
    --primary-0: #025a19; /* Main Primary color */
    --primary-1: #097b27;
    --primary-2: #066c21;
    --primary-3: #004512;
    --primary-4: #00330d;

    --secondary-1-0: #4a7002; /* Main Secondary color (1) */
    --secondary-1-1: #68990b;
    --secondary-1-2: #5b8607;
    --secondary-1-3: #385500;
    --secondary-1-4: #2a3f00;

    --secondary-2-0: #05354c; /* Main Secondary color (2) */
    --secondary-2-1: #0c4a68;
    --secondary-2-2: #09415b;
    --secondary-2-3: #03283a;
    --secondary-2-4: #021e2b;

    --grey-0: #383838;
    --grey-1: #4f4f4f;
    --grey-2: #454545;
    --grey-3: #2b2b2b;
    --grey-4: #1f1f1f;

    --red-0: #781003; /* Main Complement color */
    --red-1: #a41e0c;
    --red-2: #901808;
    --red-3: #5c0b00;
    --red-4: #440800;

    --purple-0: #650231;
    --purple-1: #890a46;
    --purple-2: #78073c;
    --purple-3: #4d0024;
    --purple-4: #39001b;

    --orange-0: #783d03;
    --orange-1: #a4580c;
    --orange-2: #904c08;
    --orange-3: #5c2e00;
    --orange-4: #442200;

    --blue-0: #0b1a52;
    --blue-1: #142870;
    --blue-2: #102262;
    --blue-3: #07133e;
    --blue-4: #050e2e;

    --yellow-0: #707703;
    --yellow-1: #99a20c;
    --yellow-2: #868e08;
    --yellow-3: #555b00;
    --yellow-4: #3f4300;

    --turquoise-0: #043d4b;
    --turquoise-1: #0a5466;
    --turquoise-2: #074a59;
    --turquoise-3: #022e39;
    --turquoise-4: #01222a;
}

.bg-primary,
.bg-primary-hover {
    background-color: var(--blue-0);
}

.bg-primary-hover:hover {
    background-color: var(--blue-1);
}

.bg-secondary,
.bg-secondary-hover {
    background-color: var(--secondary-2-0);
}

.bg-secondary-hover:hover {
    background-color: var(--secondary-2-1);
}

.bg-success,
.bg-success-hover {
    background-color: var(--primary-0);
}

.bg-success-hover:hover {
    background-color: var(--primary-1);
}

.bg-danger,
.bg-danger-hover {
    background-color: var(--red-0);
}

.bg-danger-hover:hover {
    background-color: var(--red-1);
}

.bg-warning,
.bg-warning-hover {
    background-color: var(--yellow-0);
}

.bg-warning-hover:hover {
    background-color: var(--yellow-1);
}

.bg-info,
.bg-info-hover {
    background-color: var(--turquoise-0);
}

.bg-info-hover:hover {
    background-color: var(--turquoise-1);
}

.bg-grey,
.bg-grey-hover {
    background-color: var(--grey-0);
}

.bg-grey-hover:hover {
    background-color: var(--grey-1);
}

.bg-orange,
.bg-orange-hover {
    background-color: var(--orange-0);
}

.bg-orange-hover:hover {
    background-color: var(--orange-1);
}

.bg-purple,
.bg-purple-hover {
    background-color: var(--purple-0);
}

.bg-purple-hover:hover {
    background-color: var(--purple-1);
}
.bg-light {
    background-color: var(--grey-1);
}

.bg-dark {
    background-color: var(--grey-4);
}

/* As RGBa codes */

.rgba-primary-0 {
    color: rgba(2, 90, 25, 1);
} /* Main Primary color */
.rgba-primary-1 {
    color: rgba(9, 123, 39, 1);
}
.rgba-primary-2 {
    color: rgba(6, 108, 33, 1);
}
.rgba-primary-3 {
    color: rgba(0, 69, 18, 1);
}
.rgba-primary-4 {
    color: rgba(0, 51, 13, 1);
}

.rgba-secondary-1-0 {
    color: rgba(74, 112, 2, 1);
} /* Main Secondary color (1) */
.rgba-secondary-1-1 {
    color: rgba(104, 153, 11, 1);
}
.rgba-secondary-1-2 {
    color: rgba(91, 134, 7, 1);
}
.rgba-secondary-1-3 {
    color: rgba(56, 85, 0, 1);
}
.rgba-secondary-1-4 {
    color: rgba(42, 63, 0, 1);
}

.rgba-secondary-2-0 {
    color: rgba(5, 53, 76, 1);
} /* Main Secondary color (2) */
.rgba-secondary-2-1 {
    color: rgba(12, 74, 104, 1);
}
.rgba-secondary-2-2 {
    color: rgba(9, 65, 91, 1);
}
.rgba-secondary-2-3 {
    color: rgba(3, 40, 58, 1);
}
.rgba-secondary-2-4 {
    color: rgba(2, 30, 43, 1);
}

.rgba-red-0 {
    color: rgba(120, 16, 3, 1);
} /* Main Complement color */
.rgba-red-1 {
    color: rgba(164, 30, 12, 1);
}
.rgba-red-2 {
    color: rgba(144, 24, 8, 1);
}
.rgba-red-3 {
    color: rgba(92, 11, 0, 1);
}
.rgba-red-4 {
    color: rgba(68, 8, 0, 1);
}

.rgba-purple-0 {
    color: rgba(101, 2, 49, 1);
}
.rgba-purple-1 {
    color: rgba(137, 10, 70, 1);
}
.rgba-purple-2 {
    color: rgba(120, 7, 60, 1);
}
.rgba-purple-3 {
    color: rgba(77, 0, 36, 1);
}
.rgba-purple-4 {
    color: rgba(57, 0, 27, 1);
}

.rgba-orange-0 {
    color: rgba(120, 61, 3, 1);
}
.rgba-orange-1 {
    color: rgba(164, 88, 12, 1);
}
.rgba-orange-2 {
    color: rgba(144, 76, 8, 1);
}
.rgba-orange-3 {
    color: rgba(92, 46, 0, 1);
}
.rgba-orange-4 {
    color: rgba(68, 34, 0, 1);
}

.rgba-blue-0 {
    color: rgba(11, 26, 82, 1);
}
.rgba-blue-1 {
    color: rgba(20, 40, 112, 1);
}
.rgba-blue-2 {
    color: rgba(16, 34, 98, 1);
}
.rgba-blue-3 {
    color: rgba(7, 19, 62, 1);
}
.rgba-blue-4 {
    color: rgba(5, 14, 46, 1);
}

.rgba-yellow-0 {
    color: rgba(112, 119, 3, 1);
}
.rgba-yellow-1 {
    color: rgba(153, 162, 12, 1);
}
.rgba-yellow-2 {
    color: rgba(134, 142, 8, 1);
}
.rgba-yellow-3 {
    color: rgba(85, 91, 0, 1);
}
.rgba-yellow-4 {
    color: rgba(63, 67, 0, 1);
}

.rgba-turquoise-0 {
    color: rgba(4, 61, 75, 1);
}
.rgba-turquoise-1 {
    color: rgba(10, 84, 102, 1);
}
.rgba-turquoise-2 {
    color: rgba(7, 74, 89, 1);
}
.rgba-turquoise-3 {
    color: rgba(2, 46, 57, 1);
}
.rgba-turquoise-4 {
    color: rgba(1, 34, 42, 1);
}

/* Generated by Paletton.com © 2002-2014 */
/* http://paletton.com */
