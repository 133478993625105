body {
    font-family: "Courier New", Courier, monospace;
    color: green;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-size: auto;
    background-color: #002409;
    background-image: url("./background/emeraldinspirations-tile.png"); /* fallback */
    background-image: url("./background/emeraldinspirations-tile.png"),
        linear-gradient(#004011 0%, #002409 100%); /* W3C */
}

.card {
    background-color: rgba(100, 100, 100, 0.25);
    line-height: 100%;
}

.card,
.card a,
.card a:hover,
.card a:visited {
    color: #88ff88;
    text-decoration-color: rgba(136, 255, 136, 0.4);
}

.profile .skill {
    margin: 0.25em;
    text-decoration: none;
    background-color: rgba(126, 126, 126, 0.5);
}

.profile .current {
    background-color: rgba(126, 255, 126, 0.5);
    color: black !important;
    border: black solid 1px;
}
